<template>
  <div class="full-width-padding">
    <div class="admin-news-entry">
      <div style="display: flex; justify-content: flex-start; column-gap: 30px">
        <h3 class="auth" style="margin: 0">{{ data.title[0] ? data.title[0] : data.title[1] }}</h3>
        <p class="auth text-hoverable"><router-link :to="{name: 'News-Write', params: {editModel: data} }">Redigera</router-link></p>
        <p class="auth text-hoverable" style="color: #D91919;" @click="$emit('delete')">Ta bort</p>
      </div>
      <p class="auth">{{date}}</p>
      <div style="display: flex; margin-top: 20px">
        <div v-if="data.images[0]" style="width: 100px; margin-right: 10px">
          <img class="admin-news-entry-image" :src="data.images[0]" >
        </div>
        <p class="cap4 auth" style="
            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;">
            {{ plain }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    data: {},
  },
  computed: {
    date() {
      moment.locale(this.$i18n.locale) //TODO Make this work with dynamic locale
      return moment(this.data.time).utc().format('MMM DD, YYYY')
    },
    plain() {
      let content = this.data.content[0] ? this.data.content[0] : this.data.content[1]

      let html = content.replaceAll('<br>',' ').replaceAll(/(<\/.{1,2}>{1})/g, '\n$1')
      let div = document.createElement("div");
      div.innerHTML = html;
      const plaintext = div.textContent || div.innerText;

      return plaintext
    }
  }
}
</script>

<style>
.admin-news-entry {
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
  padding: 20px;
  margin: 10px 0;
  box-sizing: border-box;
  text-align: left;
}

.admin-news-entry-image {
  min-width: none;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cap4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>