<template>
  <div class="admin-news-container">
    <div style="display: flex" class="full-width-padding">
      <p class="text-hoverable" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center">
        <BackIcon style="margin-right: 10px"/>Tillbaka
      </p>
    </div>
    <router-link to="write" append>
      <button class="auth">Skriv nytt inlägg</button>
    </router-link>
    <div v-for="(entry, index) in newsList" :key="index">
      <admin-news-entry :data="entry" @delete="deletePost(entry)" />
    </div>
  </div>
</template>

<script>
import AdminNewsEntry from '../../components/admin/AdminNewsEntry.vue'
import BackIcon from '../../assets/icons/back.svg'

export default {
  components: {
    AdminNewsEntry,
    BackIcon
  },
  computed: {
    newsList() {
      
      var keys = Object.keys(this.$store.state.news)

      keys.sort((a, b) => {
        if (a < b) return 1 
        if (a > b) return -1
        return 0
      }) 

      const news = {}
      for(const key of keys) news[key] = this.$store.state.news[key]

      return news
    }
  },
  metaInfo() {
    return {
      title: 'Admin - Nyheter'
    }
  },
  methods: {
    async deletePost(data) {
      await this.$store.dispatch('deleteNews', { data })
    }
  }
}
</script>

<style scoped>


</style>